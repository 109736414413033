<template>
    <div id="AddPatient" class="content">
        <div class="box">
            <el-form ref="form" :rules="rules" :model="formData" label-width="160px">
                <div class="form" v-show="pageIndex==1" style="display: flex ">
                    <div style="width: 50%">
                        <el-form-item label="姓名" prop="name">
                            <el-input v-model="formData.name"></el-input>
                        </el-form-item>
                        <el-form-item label="性别" prop="gender">
                            <el-radio-group v-model="formData.gender">
                                <el-radio :label="0" name="0">男</el-radio>
                                <el-radio :label="1" name="1">女</el-radio>
                            </el-radio-group>
                        </el-form-item>

                        <el-form-item label="出生日期" prop="birth">
                            <el-date-picker type="date" placeholder="选择日期" style="width:100%" v-model="formData.birth"
                                            @change="birthday" value-format="yyyy-MM-dd"></el-date-picker>
                        </el-form-item>
                        <el-form-item label="年龄">
                            <el-input v-model="formData.age" readonly></el-input>
                        </el-form-item>

                        <el-form-item label="病历号" prop="medical_num">
                            <el-input v-model="formData.medical_num"></el-input>
                        </el-form-item>
                        <el-form-item label="医保号" prop="medical_insurance_num">
                            <el-input v-model="formData.medical_insurance_num"></el-input>
                        </el-form-item>
                        <el-form-item label="民族"  prop="nation_id">
                            <el-select v-model="formData.nation_id" placeholder="请选择" style="width: 100%;">
                                <el-option v-for="item in nationalList" :key="item.id" :label="item.name"
                                           :value="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="户口类型" prop="hukou">
                            <el-radio-group v-model="formData.hukou">
                                <el-radio :label="1" name="1"  v-if="$store.state.user.hukou == 1">农村户口</el-radio>
                                <el-radio :label="2" name="2"  v-if="$store.state.user.hukou == 2">城市户口</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="学历信息" prop="education_id">
                            <el-select v-model="formData.education_id" placeholder="请选择" style="width: 100%;"
                                       @change="educationChange($event)">
                                <el-option v-for="item in educationInformationArr" :key="item.id" :label="item.name"
                                           :value="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="手机号" prop="phone">
                            <el-input v-model="formData.phone"></el-input>
                        </el-form-item>
                        <el-form-item label="身份证号" prop="idcard">
                            <el-input
                                v-model="formData.idcard"
                                @input="
                                    formData.idcard=formData.idcard.replace(
                                        /[^\X0-9]/g,
                                        ''
                                    )"
                            ></el-input>
                        </el-form-item>
                    </div>
                    <div style="width: 50%">
<!--                        <el-form-item label="婚姻状态" prop="marriage_id">-->
<!--                            <el-select v-model="formData.marriage_id" placeholder="请选择" style="width: 100%;"-->
<!--                                       @change="marriageChange($event)">-->
<!--                                <el-option v-for="item in maritalStatusArr" :key="item.id" :label="item.name"-->
<!--                                           :value="item.id"></el-option>-->
<!--                            </el-select>-->
<!--                        </el-form-item>-->


<!--                        <el-form-item label="职业信息" prop="occupation_id">-->
<!--                            <el-select v-model="formData.occupation_id" placeholder="请选择" style="width: 100%;"-->
<!--                                       @change="occupationChange($event)">-->
<!--                                <el-option v-for="item in careerInformationArr" :key="item.id" :label="item.name"-->
<!--                                           :value="item.id"></el-option>-->
<!--                            </el-select>-->
<!--                        </el-form-item>-->
                        <el-form-item label="入院时间">
                            <el-date-picker type="date" placeholder="选择日期" style="width:100%" value-format="yyyy-MM-dd"
                                            v-model="formData.in_time"></el-date-picker>
                        </el-form-item>
                        <el-form-item label="患者来源" prop="source">
                            <div style="display: flex;width: 100%;">
                                <el-select v-model="department_name" placeholder="请选择" @change="getRole($event)">
                                    <el-option v-for="item in stateArr" :key="item.id" :label="item.name"
                                               :value="item.name"></el-option>
                                </el-select>
                                <el-select :disabled="disabled" v-model="formData.source" placeholder="请选择" style="margin-left: 10px">
                                    <el-option v-for="(item,index) in select2" :key="item.id" :label="item.name"
                                               :value="item.name"></el-option>
                                </el-select>
                            </div>
                            <!-- <el-input v-model="formData.source"></el-input> -->
                        </el-form-item>
                        <el-form-item label="测试次数" prop="check_num">
                            <el-input v-model="formData.check_num"></el-input>
                        </el-form-item>
<!--                        <el-form-item label="父亲姓名" prop="father_name">-->
<!--                            <el-input v-model="formData.father_name"></el-input>-->
<!--                        </el-form-item>-->
<!--                        <el-form-item label="父亲学历" prop="father_education_id">-->
<!--                            <el-select v-model="formData.father_education_id" placeholder="请选择" style="width: 100%;"-->
<!--                                       @change="educationChange($event,'father')">-->
<!--                                <el-option v-for="item in educationInformationArr" :key="item.id" :label="item.name"-->
<!--                                           :value="item.id"></el-option>-->
<!--                            </el-select>-->
<!--                        </el-form-item>-->
<!--                        <el-form-item label="父亲职业" prop="father_occupation_id">-->
<!--                            <el-select v-model="formData.father_occupation_id" placeholder="请选择" style="width: 100%;"-->
<!--                                       @change="occupationChange($event,'father')">-->
<!--                                <el-option v-for="item in careerInformationArr" :key="item.id" :label="item.name"-->
<!--                                           :value="item.id"></el-option>-->
<!--                            </el-select>-->
<!--                        </el-form-item>-->
<!--                        <el-form-item label="母亲姓名" prop="mother_name">-->
<!--                            <el-input v-model="formData.mother_name"></el-input>-->
<!--                        </el-form-item>-->
<!--                        <el-form-item label="母亲学历" prop="mother_education_id">-->
<!--                            <el-select v-model="formData.mother_education_id" placeholder="请选择" style="width: 100%;"-->
<!--                                       @change="educationChange($event,'mother')">-->
<!--                                <el-option v-for="item in educationInformationArr" :key="item.id" :label="item.name"-->
<!--                                           :value="item.id"></el-option>-->
<!--                            </el-select>-->
<!--                        </el-form-item>-->
<!--                        <el-form-item label="母亲职业" prop="mother_occupation_id">-->
<!--                            <el-select v-model="formData.mother_occupation_id" placeholder="请选择" style="width: 100%;"-->
<!--                                       @change="occupationChange($event,'mother')">-->
<!--                                <el-option v-for="item in careerInformationArr" :key="item.id" :label="item.name"-->
<!--                                           :value="item.id"></el-option>-->
<!--                            </el-select>-->
<!--                        </el-form-item>-->
                        <el-form-item label="检测人员" prop="check_id">
                            <el-select v-model="formData.check_id" placeholder="请选择" style="width: 100%;"
                                       @change="checkChange($event)">
                                <el-option v-for="item in checkArr" :key="item.id" :label="item.name"
                                           :value="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                </div>
            </el-form>
            <div class="margin-top box-nowrap footer">
                <el-button round class="cancel" @click="$router.go(-1)">取消</el-button>
                <el-button round class="save" @click="submit">{{ $route.query.id ? '保存' : '添加' }}</el-button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "AddPatient",
    data() {
        return {
            readonly: true,
            stateArr: [],
            maritalStatusArr: [],
            educationInformationArr: [],
            careerInformationArr: [],
            nationArr: [],
            pageIndex: 1,
            formData: {
                age: '',
                in_time: '',
                marriage_id: '',
                education_id: '',
                occupation_id: '',
                source:'',
                nation_id:'',
                check_num:'',
                // father_education_id:'',
                // mother_education_id:'',
                // mother_occupation_id:'',
                // father_occupation_id:'',
                check_id:'',
                idcard:''
            },
            backup: {},
            zhixiSelect: [],
            rules: {
                // name: [{
                //     required: true,
                //     message: '请输入姓名',
                //     trigger: 'blur'
                // }],
                // gender: [{
                //     required: true,
                //     message: '请选择性别'
                // }],
                // hukou:[
                //     {required:true,message:'请选择户口类型'}
                // ],
                // medical_num: [{
                //     required: true,
                //     message: '请输入病历号'
                // }],
                // medical_insurance_num: [{
                //     required: true,
                //     message: '请输入医保号'
                // }],
                // birth: [{
                //     required: true,
                //     message: '请选择出生日期'
                // }],
                // phone: [{
                //     message: '请输入手机号'
                // }],
                // source: [{
                //     message: '请输入患者来源'
                // }],
                // marriage_id: [{
                //     required: true,
                //     message: '请输入婚姻状态'
                // }],
                // education_id: [{
                //     required: true,
                //     message: '请输入学历信息'
                // }],
                // occupation_id: [{
                //     required: true,
                //     message: '请输入职业信息'
                // }],
            },
            wardList: [],
            page_two: true,
            page_One: false,
            department_name:'',
            select2:[],
            disabled:true,
            nationalList:[],
            checkArr:[]

        };
    },
    computed: {},
    watch: {},
    async mounted() {
        await this.getWard();
        if (this.$route.query.id) {
            this.getInfo()
        } else {
            this.formData.hukou = Number(this.$store.state.user.hukou)
            this.$forceUpdate()
        }
        sessionStorage.setItem("detail", true);

    },
    methods: {
        /**
         * 检测人员选择
         * */
        checkChange(e) {
            this.formData.check_id = e
        },
        /**
         * 患者来源选择
         * */
        getRole (prov) {
            this.select2= []
            for (var val of this.stateArr) {
                if (prov===val.name) {
                    this.select2 = val.child
                }
            }
            this.disabled = false;
            this.formData.source = ''
        },
        marriageChange(e) {
            this.formData.marriage_id = e
            console.log('marriage_id', this.formData.marriage_id)
        },
        educationChange(e,i) {
            if(i == 'father') {
                this.formData.father_education_id = e
            } else if (i == 'mother') {
                this.formData.mother_education_id = e
            } else {
                this.formData.education_id = e
            }

        },
        occupationChange(e,i) {
            if(i == 'father') {
                this.formData.father_occupation_id = e
            } else if (i == 'mother') {
                this.formData.mother_occupation_id = e
            } else {
                this.formData.occupation_id = e
            }

        },
        submit() {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    let formData = {
                        ...this.formData
                    };
                    if (!this.$route.query.id) {
                        //添加
                        console.log(formData)
                        this.$axios.post('api/patient/add', this.$qs.stringify(formData)).then(res => {
                            if (res.data.code === 1) {
                                this.$message.success(res.data.info)
                                this.$router.go(-1)
                            }
                        })
                    } else {
                        //编辑
                        console.log(this.formData)
                        let form = {
                            id: formData.id,
                            name: formData.name
                        };
                        for (let backupKey in this.backup) {
                            if (typeof this.backup[backupKey] == 'object') {
                                form[backupKey] = this.formData[backupKey];
                            } else if (this.backup[backupKey] !== this.formData[backupKey]) {
                                form[backupKey] = this.formData[backupKey];
                            }
                        }
                        this.$axios.post('api/patient/edit', this.$qs.stringify(form)).then(res => {
                            console.log('form', form)
                            if (res.data.code === 1) {
                                this.$message.success(res.data.info)
                                this.$router.go(-1)
                            }
                        })
                    }
                }
            });
        },
        /**
         * 编辑接口
         * */
        getInfo() {
            this.$axios.post('api/patient/info', {
                id: this.$route.query.id
            }).then(res => {
                var data = res.data.data
                this.department_name = data.department_name
                this.disabled = false
                this.formData = data
                for (var val of this.stateArr) {
                    if (this.department_name===val.name) {
                        this.select2 = val.child
                    }
                }
                // if(this.formData.father_education_id == 0) {
                //     this.formData.father_education_id = ''
                // }
                // if(this.formData.mother_education_id == 0) {
                //     this.formData.mother_education_id = ''
                // }
                // if(this.formData.mother_occupation_id == 0) {
                //     this.formData.mother_occupation_id = ''
                // }
                // if(this.formData.father_occupation_id == 0) {
                //     this.formData.father_occupation_id = ''
                // }
                if(this.formData.education_id == 0) {
                    this.formData.education_id = ''
                }
                if(this.formData.nation_id == 0) {
                    this.formData.nation_id = ''
                }
                if(this.formData.check_num == 0) {
                    this.formData.check_num = ''
                }
                if(this.formData.check_id == 0) {
                    this.formData.check_id = ''
                }
                this.backup = {
                    ...data
                };
            })
        },
        //接口 数据婚姻 学历信息  职业  患者来源等
        async getWard() {
            await this.$axios.post('api/patient/check_list').then(res => {
                this.checkArr = res.data.data.list;
            })
            await this.$axios.post('api/district/nation').then(res => {
                this.nationalList = res.data.data;

            })
            await this.$axios.post('api/district/index', {type: 1}).then(res => {
                this.stateArr = res.data.data;

            })
            await this.$axios.post('api/common/marriage').then(res => {
                console.log(res)
                this.maritalStatusArr = res.data.data;
            })
            await this.$axios.post('api/common/education').then(res => {
                console.log(res)
                this.educationInformationArr = res.data.data;
            })
            await this.$axios.post('api/common/occupation').then(res => {
                console.log(res)
                this.careerInformationArr = res.data.data;
            })
        },
        birthday(e) {
            this.formData.age = this.getAge(e.split('-'))[0];
            if (this.formData.age < 0) {
                this.formData.age = 0
            }
        },
        getAge(birthday) {
            // 新建日期对象
            let date = new Date()
            // 今天日期，数组，同 birthday
            let today = [date.getFullYear(), date.getMonth() + 1, date.getDate()]
            // 分别计算年月日差值
            let age = today.map((value, index) => {
                return value - birthday[index]
            })
            // 当天数为负数时，月减 1，天数加上月总天数
            if (age[2] < 0) {
                // 简单获取上个月总天数的方法，不会错
                let lastMonth = new Date(today[0], today[1], 0)
                age[1]--
                age[2] += lastMonth.getDate()
            }
            // 当月数为负数时，年减 1，月数加上 12
            if (age[1] < 0) {
                age[0]--
                age[1] += 12
            }
            return age
        }
    },
}
</script>

<style scoped lang="scss">
::v-deep {
    .el-radio__input.is-disabled + span.el-radio__label {
        color: #333333;
    }
}
.tip {
    padding-left: 100px;
    line-height: 48px;
    color: #8197FE;
    background: #FFF3F3;
    margin-bottom: 20px;
}

.footer {
    display: flex;
    justify-content: flex-end;
}

.content {
    padding: 0 35px;
    background-color: #FFF;
}

.box {
    margin: 40px;
}

.form {
    /*display: flex;*/
    /*flex-wrap: wrap;*/
    /*justify-content: space-between;*/
    width: 100%;
}

.form-box {
    justify-content: space-between;
    display: flex;

    ::v-deep .el-form {
        width: 40%;
    }

}

::v-deep .el-form-item {
    /*width: 40%;*/
}

.cancel {
    height: 50px;
    width: 150px;
    text-align: center;
    border-radius: 50px;
    color: #FFF;
    font-size: 20px;
    background: #FECB72;
    border-color: #FECB72!important;
    //box-shadow: 4px 7px 10px 0px rgba(245, 96, 90, 20%);
}

.save {
    height: 50px;
    width: 150px;
    text-align: center;
    border-radius: 50px;
    color: #FFF;
    font-size: 20px;
    background: #8197FE;
    border-color: #8197FE!important;
    //box-shadow: 4px 7px 10px 0px rgba(243, 147, 53, 20%);
}

::v-deep .el-select .el-input__inner:focus {
    border-color: #8197FE;
}

::v-deep .el-select .el-input.is-focus .el-input__inner {
    border-color: #8197FE;
}

::v-deep .el-form-item.is-error .el-input__inner,
.el-form-item.is-error .el-input__inner:focus,
.el-form-item.is-error .el-textarea__inner,
.el-form-item.is-error .el-textarea__inner:focus {
    border-color: #8197FE;
}

::v-deep .el-form-item__error {
    color: #8197FE;
}

::v-deep .el-radio__input.is-checked+.el-radio__label {
    color: #8197FE;
}

::v-deep .el-radio__input.is-checked .el-radio__inner {
    background: #8197FE;
    border-color: #8197FE;
}

::v-deep .el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label:before,
.el-form-item.is-required:not(.is-no-asterisk) .el-form-item__label-wrap>.el-form-item__label:before {
    color: #8197FE;
}

::v-deep .el-date-table td.current:not(.disabled) span {
    background: #8197FE;
}

::v-deep .el-form-item__label {
    font-size: 18px;
}

::v-deep .el-radio__label {
    font-size: 18px;
}

.box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

::v-deep .el-form-item__content {
    width: 30%;
    min-width: 300px;
}

::v-deep .el-form--inline {
    display: flex;
    justify-content: space-between;

}

::v-deep .el-form--inline .el-form-item {
    width: 50%;
}

::v-deep .el-form--inline .el-input__inner {
    width: 300px;
}

::v-deep .add-input .el-input__inner {
    width: 230px;
    display: inline-block;
}

.add-input {
    margin-bottom: 10px;
}

::v-deep .el-form-item {
    position: relative;
}

.add-btn {
    width: 40px;
    height: 40px;
    background: #F2F2F2;
    border-radius: 2px;
    border: 1px solid #D2D2D2;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
}

.drop-down {}
</style>
